<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        For the reaction shown below, determine whether each species is acting as a Bronsted-Lowry
        acid or a Bronsted-Lowry base:
      </p>

      <p class="mb-7 pl-12">
        <chemical-latex content="H2Y-(aq) + H2Z-(aq) <=> H3Y(aq) + HZ^{2-}(aq)" />
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Species</th>
            <th style="font-size: 15px">Acid</th>
            <th style="font-size: 15px">Base</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <chemical-latex content="H2Y^{-}(aq)" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="acid" value="acid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="base" value="base" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <chemical-latex content="H2Z^{-}(aq)" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="acid" value="acid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="base" value="base" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <chemical-latex content="H3Y(aq)" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="acid" value="acid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="base" value="base" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <chemical-latex content="HZ^{2-}(aq)" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="acid" value="acid" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="base" value="base" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question133',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      options: [
        {acidOrBase: '$\\text{Acid}$', value: 'acid'},
        {acidOrBase: '$\\text{Base}$', value: 'base'},
      ],
    };
  },
};
</script>
